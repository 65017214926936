<template>
  <div>
    <el-container style="height: 100vh;">
      <el-aside id="menu" width="220px" style="background: #00AAA6">
        <div class="menu_headline">
          <!--                    <h2>Hisense</h2>-->
          <img src="../assets/logo.png" alt="" />
        </div>
        <sider-menu></sider-menu>
      </el-aside>
      <el-container direction="vertical">
        <Headers></Headers>
        <el-main style="background: #fcfcfd; padding: 20px 40px">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Headers from "./header";
// import Footer from "./footer"
import siderMenu from "./siderMenu";
export default {
  name: "basicLayout",
  data() {
    return {
      levelList: []
    };
  },
  components: {
    siderMenu,
    Headers
  },
  watch: {
    // $route() {
    //     this.getBreadcrumb()  //监听路由变化
    // }
  },
  created() {
    // this.getBreadcrumb()
  },
  methods: {
    // getBreadcrumb() {
    //     let matched = this.$route.matched.filter(item => item.name)  //获取路由信息，并过滤保留路由名称信息存入数组
    //     this.levelList = matched
    //     // console.log(matched)
    // }
  }
};
</script>

<style scoped>
.menu_headline {
  margin-top: 36px;
  margin-bottom: 110px;
  text-align: center;
  color: #ececec;
  font-size: 30px;
}
.head_portrait {
  background: url("../assets/timg.jpg") center/cover no-repeat;
  width: 34px;
  height: 34px;
  margin-top: 18px;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
  text-decoration: none;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
  text-decoration: none;
}
/deep/ .el-dropdown {
  display: inline-block;
  position: relative;
  color: #606266;
  font-size: 14px;
  width: 70px;
  height: 20px;
}
</style>
