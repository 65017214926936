var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-menu',{attrs:{"popper-class":"menu","active-text-color":"fff","background-color":"#00AAA6","text-color":"#fff"}},[_vm._l((_vm.asyncRoute),function(item,index){return [(
          item.meta.menu &&
            _vm.func.getRoot(
              _vm.dataList.rootList.find(el => el.name === item.name).value
            )
        )?_c('router-link',{key:index,attrs:{"to":item.path,"index":index}},[_c('el-menu-item',{attrs:{"id":"index"}},[_c('svg-icon',{attrs:{"icon-class":item.meta.icon || '',"class-name":"icon"}}),_c('span',[_vm._v(_vm._s(item.name))])],1)],1):_vm._e(),(
          item.children &&
            !item.meta.menu &&
            _vm.func.getRoot(
              _vm.dataList.rootList.find(el => el.name === item.name).value
            )
        )?_c('el-submenu',{key:index + '-',attrs:{"index":index + ''}},[_c('template',{slot:"title"},[_c('svg-icon',{attrs:{"icon-class":item.meta.icon || '',"class-name":"icon"}}),_vm._v(" "+_vm._s(item.name)+" ")],1),_vm._l((item.children),function(ite,ind){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(
            ite.meta.showRole &&
              _vm.func.getRoot(
                _vm.dataList.rootList.find(el => el.name === ite.name).value
              )
          ),expression:"\n            ite.meta.showRole &&\n              func.getRoot(\n                dataList.rootList.find(el => el.name === ite.name).value\n              )\n          "}],key:ind + '-' + index,staticStyle:{"color":"#fff"},attrs:{"to":ite.path,"index":ind + '-' + index}},[_c('el-menu-item',[_vm._v(_vm._s(ite.name))])],1)})],2):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }