<template>
  <div>
    <el-menu
      popper-class="menu"
      active-text-color="fff"
      background-color="#00AAA6"
      text-color="#fff"
    >
      <template v-for="(item, index) in asyncRoute">
        <router-link
          :key="index"
          :to="item.path"
          v-if="
            item.meta.menu &&
              func.getRoot(
                dataList.rootList.find(el => el.name === item.name).value
              )
          "
          :index="index"
        >
          <el-menu-item id="index">
            <svg-icon
              :icon-class="item.meta.icon || ''"
              class-name="icon"
            ></svg-icon>
            <span>{{ item.name }}</span>
          </el-menu-item>
        </router-link>
        <el-submenu
          v-if="
            item.children &&
              !item.meta.menu &&
              func.getRoot(
                dataList.rootList.find(el => el.name === item.name).value
              )
          "
          :key="index + '-'"
          :index="index + ''"
        >
          <template slot="title">
            <svg-icon
              :icon-class="item.meta.icon || ''"
              class-name="icon"
            ></svg-icon>
            {{ item.name }}
          </template>
          <router-link
            v-for="(ite, ind) in item.children"
            :key="ind + '-' + index"
            v-show="
              ite.meta.showRole &&
                func.getRoot(
                  dataList.rootList.find(el => el.name === ite.name).value
                )
            "
            style="color: #fff"
            :to="ite.path"
            :index="ind + '-' + index"
          >
            <el-menu-item>{{ ite.name }}</el-menu-item>
          </router-link>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { asyncRoute } from "@/router";
export default {
  name: "siderMenu",
  data() {
    return {
      index: false,
      levelList: []
    };
  },
  methods: {
    nf() {
      let objAfter = JSON.parse(localStorage.getItem("fns"));
      for (let i = 0; i < objAfter.length; i++) {
        console.log(objAfter[i]);
      }
    },
    getMenuItem() {
      let matched = this["$router"].options.routes;
      console.log(matched);
    }
  },
  created() {
    this.$store.dispatch("menu/getMenu");
  },
  computed: {
    asyncRoute() {
      return asyncRoute;
    }
  }
};
</script>

<style scoped>
#sidebarAssets {
  padding-left: 48px !important;
}
#index {
  padding-left: 48px !important;
}
/deep/ .el-menu-item,
.el-submenu__title {
  padding-left: 80px !important;
}
/deep/ .el-submenu__title {
  padding-left: 48px !important;
}
/deep/ .el-form-item__label {
  color: #858585 !important;
}
/* 去掉a标签默认样式*/
a {
  text-decoration: none !important;
}
.router-link-active {
  text-decoration: none;
}
/deep/ .el-dropdown {
  display: inline-block;
  position: relative;
  color: #606266;
  font-size: 14px;
  width: 70px;
  height: 20px;
}
.icon {
  width: 20%;
  height: 40%;
}

/deep/.el-menu {
  padding: 0;
}
</style>
