<template>
  <div class="layoutBox">
    <el-header class="header">
      <!-- <el-header
      style="padding-right: 20px; text-align: right; font-size: 12px;height: 70px;border: 1px solid #E8EAF0;border-radius: 3px;border-radius: 3px;"
    > -->
      <div>
        <!-- <div style="float: left;margin-top: 25px"> -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(item, index) in levelList"
            :key="item.path"
          >
            <router-link :to="index == 0 ? '' : item.path">{{
              item.name
            }}</router-link>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- <div style="float: right;margin-top: 25px;margin-right: 40px;"> -->
      <div class="right">
        <el-badge :value="messageNum" class="item">
          <div @click="jup()">
            <svg-icon icon-class="icon_news_30x30" class-name="icon"></svg-icon>
          </div>
          <!--                    <el-button size="small">评论</el-button>-->
        </el-badge>
        <el-avatar size="large" :src="circleUrl"></el-avatar>
        <!-- <el-avatar size="large" :src="circleUrl"></el-avatar> -->
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ userName }}<i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="用户">{{ userName }}</el-dropdown-item>
            <el-divider></el-divider>
            <el-dropdown-item command="个人资料">个人资料</el-dropdown-item>
            <el-dropdown-item command="修改密码">修改密码</el-dropdown-item>
            <el-divider></el-divider>
            <el-dropdown-item command="退出">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
  </div>
</template>

<script>
import { loginout, userGetImage } from "../api/AssetManagement";
export default {
  name: "headers",
  data() {
    return {
      levelList: [],
      messageNum: "",
      userName: localStorage.getItem("userName"),
      circleUrl: ""
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb(); //监听路由变化
    }
  },
  created() {
    this.messageNum = localStorage.getItem("messageNum");
    this.getBreadcrumb();
    userGetImage().then(({ data }) => { //后台问题
      if (data.resultCode === 0) {
        this.circleUrl = data.data;
      }
    });
  },
  methods: {
    //跳转
    jup() {
      console.log("=======");
      this.$router.push("/dashboard/message");
    },
    //菜单
    handleCommand(command) {
      if (command == "退出") {
        let userId = localStorage.getItem("userId");
        loginout({ isApp: 0, userId }).then(res => {
          console.log(res);
          localStorage.setItem("token", "");
          localStorage.setItem("userId", "");
          this.$router.push("/login");
        });
      } else if (command == "个人资料") {
        this.$router.push("/dashboard/account/PIM");
      } else if (command == "修改密码") {
        this.$router.push("/dashboard/account/alterPassword");
      }
    },
    //面包屑
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name); //获取路由信息，并过滤保留路由名称信息存入数组
      this.levelList = matched;
      // console.log(matched)
    }
  }
};
</script>

<style scoped lang="scss">
/*/deep/ .el-breadcrumb:last-child span{*/
/*    color: #97a8be !important;*/
/*}*/
.icon {
  height: 30px !important;
  width: 30px !important;
}
.news {
  width: 30px;
  height: 30px;
}
.el-dropdown-link {
  cursor: pointer;
  font-size: 14px;
  color: #2b2b2b;
  letter-spacing: -0.04px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
/*分割线*/
.el-divider--horizontal {
  margin: 5px 0;
}
.layoutBox {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .el-badge {
        margin-top: 13px;
      }
    }
  }
}
</style>
